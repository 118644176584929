<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-row>
      <v-col>
        <span
          style="cursor: pointer"
          @click="navBack"
        >
          <v-icon
            style="
            font-size: 16px;
            color: #7A1878;
          "
          >
            fas fa-chevron-left
          </v-icon>
          <span
            class="ml-1"
            style="color: #7A1878"
          >
            Kembali
          </span>
        </span>
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col style="font-size: 26px">
        Detail Pesanan
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col>
        <!-- Informasi Umum -->
        <v-card class="px-4 pt-4 pb-6">
          <v-row>
            <v-col class="font-common-black-bold">
              Informasi Umum
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span>
                Nama Mitra
              </span>

              <span class="mt-2 font-common-bold">
                {{ detail.prt_comp_name }}
              </span>
            </v-col>

            <v-col
              class="font-common"
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span>
                Nama Penyewa
              </span>

              <span class="mt-2 font-common-bold">
                {{ detail.cust_name }}
              </span>
            </v-col>

            <v-col
              class="font-common"
              cols="12"
              sm="6"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span>
                Nama Media
              </span>

              <span class="mt-2 font-common-bold">
                {{ detail.ass_name }}
              </span>
            </v-col>
          </v-row>
        </v-card>

        <!-- Informasi Pesanan -->
        <v-card class="px-4 pt-4 pb-6">
          <v-row>
            <v-col class="font-common-black-bold">
              Informasi Pesanan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                Kode Pesanan
              </span>

              <span class="font-common-bold">
                {{ detail.code }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                Invoice
              </span>

              <span class="font-common-bold">
                {{ detail.invoices[0].inv_code || '-' }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                Kode Aset
              </span>

              <span class="font-common-bold">
                {{ detail.ass_code }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                Tanggal Pesanan
              </span>

              <span class="font-common-bold">
                {{ detail.tanggal_pesan }}
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                Durasi Tayang
              </span>

              <span class="font-common-bold">
                {{ detail.start_date + ' - ' + detail.end_date + ' (' + detail.total_days + ' Hari)' }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                Nominal Pembayaran
              </span>

              <span class="font-common-bold">
                {{ 'Rp. ' + (detail.total_amount || 0) | dotThousandSeparator }}
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="3"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                Status Pesanan
              </span>

              <span class="font-common-bold">
                {{ detail.status_string }}
              </span>
            </v-col>
          </v-row>
        </v-card>

        <!-- Dokumen Pesanan -->
        <v-card class="px-4 pt-4 pb-6">
          <v-row>
            <v-col class="font-common-black-bold">
              Dokumen Pesanan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="4"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                {{ detail.doc_prt_invoice._notes }}
              </span>

              <span>
                {{ detail.doc_prt_invoice.file_name || '-' + ' (' + detail.doc_prt_invoice.file_size + ')' }}
                <v-icon
                  class="ml-2"
                  style="cursor: pointer"
                >
                  fas fa-arrow-alt-circle-down
                </v-icon>
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                {{ detail.doc_prt_invoice_skpd._notes }}
              </span>

              <span>
                {{ detail.doc_prt_invoice_skpd.file_name || '-' + ' (' + detail.doc_prt_invoice_skpd.file_size + ')' }}
                <v-icon
                  class="ml-2"
                  style="cursor: pointer"
                >
                  fas fa-arrow-alt-circle-down
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="4"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                {{ detail.doc_prt_skpd._notes }}
              </span>

              <span>
                {{ detail.doc_prt_skpd.file_name || '-' + ' (' + detail.doc_prt_skpd.file_size + ')' }}
                <v-icon
                  class="ml-2"
                  style="cursor: pointer"
                >
                  fas fa-arrow-alt-circle-down
                </v-icon>
              </span>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span class="font-common">
                {{ detail.doc_tax_inv_prt._notes }}
              </span>

              <span>
                {{ detail.doc_tax_inv_prt.file_name || '-' + ' (' + detail.doc_tax_inv_prt.file_size + ')' }}
                <v-icon
                  class="ml-2"
                  style="cursor: pointer"
                >
                  fas fa-arrow-alt-circle-down
                </v-icon>
              </span>
            </v-col>
          </v-row>
        </v-card>

        <!-- Faktur Pajak dan Bukti Bayar -->
        <v-card class="px-4 pt-4 pb-7">
          <v-row>
            <v-col
              class="font-common-black-bold"
              cols="12"
              sm="6"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span> Faktur Pajak Media Travikr </span>
              <div class="d-flex">
                <template v-if="pesananMod">
                  <div
                    v-if="fakturPajak"
                    class="my-3 font-common"
                    style="
                    display: flex;
                    align-items: center;
                    width: 376px;
                    height: 46px;
                  "
                  >
                    {{ fakturPajak.file_name }}
                    <span>
                      <v-icon
                        class="ml-2"
                        style="cursor: pointer"
                        @click="downloadFakturPajak"
                      >
                        fas fa-arrow-alt-circle-down
                      </v-icon>
                    </span>
                  </div>
                  <div
                    v-else
                    class="my-3"
                    style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 376px;
                    height: 46px;
                    background: #FAFAFA;
                    border: 1px dashed #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 4px;
                    cursor: pointer;
                  "
                    @click="uploadFakturPajakClick"
                  >
                    <span style="color: #E6E6E6">
                      Masukkan file atau foto faktur pajak
                    </span>
                  </div>
                </template>

                <template v-if="pesananMod">
                  <v-btn
                    v-if="detail.doc_tax_inv_trv.can_upload"
                    class="ml-3 my-3 elevation-0"
                    style="
                    width: 113px;
                    height: 46px;
                    background: #FFFFFF;
                    border: 1px solid #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 4px;
                  "
                    @click="uploadFakturPajakClick"
                  >
                    <span
                      class="text-capitalize"
                      style="color: #7A1878"
                    >
                      Upload File
                    </span>
                  </v-btn>
                  <v-file-input
                    id="upload-faktur-pajak"
                    style="display: none"
                    @change="uploadFakturPajakChange"
                  />
                </template>

                <template v-else>
                  <v-row>
                    <v-col class="font-common-bold pt-4">
                      Tidak ada akses
                    </v-col>
                  </v-row>
                </template>
              </div>

              <div
                v-if="detail.doc_tax_inv_trv.can_upload && pesananMod"
                class="d-flex"
              >
                <v-btn
                  class="mt-3"
                  width="350px"
                  height="46px"
                  style="
                    background: #7A1878;
                    border: none;
                    outline: none;
                  "
                  @click="uploadFakturPajak"
                >
                  <span class="text-capitalize">
                    Simpan Faktur Pajak
                  </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-row class="mt-5">
            <v-col
              class="font-common-black-bold"
              cols="12"
              sm="6"
              style="
                display: flex;
                flex-direction: column;
              "
            >
              <span> Bukti Bayar PPH 2% </span>
              <template v-if="pesananMod">
                <div class="d-flex">
                  <div
                    v-if="buktiBayarPph"
                    class="my-3 font-common"
                    style="
                    display: flex;
                    align-items: center;
                    width: 376px;
                    height: 46px;
                  "
                  >
                    {{ buktiBayarPph.file_name }}
                    <span>
                      <v-icon
                        class="ml-2"
                        style="cursor: pointer"
                        @click="downloadBuktiBayarPph"
                      >
                        fas fa-arrow-alt-circle-down
                      </v-icon>
                    </span>
                  </div>
                  <div
                    v-else
                    class="my-3"
                    style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 376px;
                    height: 46px;
                    background: #FAFAFA;
                    border: 1px dashed #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 4px;
                    cursor: pointer;
                  "
                    @click="uploadBuktiBayarPphClick"
                  >
                    <span style="color: #E6E6E6">
                      Masukkan file atau foto faktur pajak
                    </span>
                  </div>

                  <v-btn
                    v-if="detail.doc_trv_pph.can_upload"
                    class="ml-3 my-3 elevation-0"
                    style="
                    width: 113px;
                    height: 46px;
                    background: #FFFFFF;
                    border: 1px solid #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 4px;
                  "
                    @click="uploadBuktiBayarPphClick"
                  >
                    <span
                      class="text-capitalize"
                      style="color: #7A1878"
                    >
                      Upload File
                    </span>
                  </v-btn>

                  <v-file-input
                    id="upload-bayar-pph"
                    style="display: none"
                    @change="uploadBuktiBayarPphChange"
                  />
                </div>
              </template>

              <template v-if="pesananMod">
                <div
                  v-if="detail.doc_trv_pph.can_upload"
                  class="d-flex"
                >
                  <v-btn
                    class="mt-3"
                    width="350px"
                    height="46px"
                    style="
                    background: #7A1878;
                    border: none;
                    outline: none;
                  "
                    @click="uploadBayarPph"
                  >
                    <span class="text-capitalize">
                      Simpan
                    </span>
                  </v-btn>
                </div>
              </template>

              <template v-else>
                <v-row>
                  <v-col class="font-common-bold pt-4">
                    Tidak ada akses
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-card>

        <!-- Periode Tayang -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Periode Tayang
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Kode Produksi
                      </th>
                      <th class="text-left">
                        Durasi
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(v, i) in detail.period"
                      :key="'period-' + i"
                    >
                      <td>{{ v.prod_code }}</td>
                      <td>
                        {{ v.start_date + '-' + v.end_date + ' (' + v.duration + ')' }}
                      </td>
                      <td>{{ v.prod_status }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- Rincian Transaksi -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Rincian Transaksi
            </v-col>
          </v-row>

          <div
            v-for="(v, i) in detail.payout_detail"
            :key="'payout-detail-' + i"
          >
            <v-row>
              <v-col
                class="font-common"
                cols="2"
                sm="2"
              >
                {{ v.text }}
              </v-col>

              <v-col
                class="d-flex font-common"
                cols="2"
                sm="2"
                style="justify-content: space-between"
              >
                <span class="font-common-bold">
                  Rp.
                </span>

                <span class="font-common-bold">
                  {{ v.val | dotThousandSeparator }}
                </span>
              </v-col>
            </v-row>
            <template v-if="v.line">
              <v-row>
                <v-col
                  cols="4"
                  sm="4"
                >
                  <v-divider />
                </v-col>
              </v-row>
            </template>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOG BLOKIR PENYEWA -->
    <!-- <v-dialog
      v-model="dialogBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin memblokir mitra ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Masukkan Password"
              color="purple"
              type="password"
              outlined
              dense
              auto-complete="off"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(2)"
            >
              Ya, Lanjutkan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      detail: {
        hash: '',
        code: '',
        status: 3,
        status_color: '',
        status_string: '',
        prt_comp_name: '',
        cust_name: '',
        start_date: '',
        end_date: '',
        total_days: 0,
        ass_share_link: '',
        ass_type: '',
        ass_name: '',
        ass_code: '',
        total_amount: 0,
        tanggal_pesan: '',
        invoices: [
          {
            inv_code: '',
            inv_hash: '',
          },
        ],
        doc_tax_inv_prt: {
          file_name: null,
          file_size: '0Kb',
          link: null,
          can_upload: true,
          _notes: 'Bukti bayar pajak dari mitra',
        },
        doc_tax_inv_trv: {
          file_name: null,
          file_size: '0Kb',
          link: null,
          can_upload: true,
          _notes: 'Bukti bayar pajak dari travikr',
        },
        doc_prt_invoice: {
          file_name: null,
          file_size: '0Kb',
          link: null,
          can_upload: true,
          _notes: 'Invoice Sewa, Cetak, Pasang dari mitra',
        },
        doc_prt_skpd: {
          file_name: null,
          file_size: '0Kb',
          link: null,
          can_upload: true,
          _notes: 'Bukti Bayar Retribusi Daerah dari mitra',
        },
        doc_prt_invoice_skpd: {
          file_name: null,
          file_size: '0Kb',
          link: null,
          can_upload: true,
          _notes: 'Invoice Penggantian Retribusi Daerah dari mitra',
        },
        doc_trv_pph: {
          file_name: null,
          file_size: '0Kb',
          link: null,
          can_upload: true,
          _notes: 'Bukti bayar pph2% dari travikr',
        },
        period: [
          {
            seq: 1,
            start_date: '25 June 2021',
            end_date: '24 July 2021',
            is_addon: 0,
            duration: 30,
            prod_code: '-',
            prod_hash: '-',
            prod_status: '-',
            prod_status_color: 'Not Found',
          },
        ],
        payout_detail: [
          {
            text: 'Total Penjualan',
            val: 22931764,
            line: false,
            payment_reff: null,
          },
        ],
      },

      // Informasi Aset:
      headerInformasiAset: [
        { text: 'Kode', align: 'start', value: 'code', sortable: false },
        { text: 'Nama Media', value: 'name' },
        { text: 'Lokasi', value: 'Yogyakarta' },
        { text: 'Status', value: 'status_string' },
      ],
      informasiAset: [],

      pageInformasiAset: 1,
      pageCountInformasiAset: 0,
      itemCountInformasiAset: 0,
      itemTotalInformasiAset: 0,

      // Upload file:
      fakturPajak: null,
      buktiBayarPph: null,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      newsLetter () {
        let r = ''
        this.detail.newsletter_subscribe ? r = 'Langganan' : r = 'Tidak Berlangganan'

        return r
      },

      pesananMod () {
        let v = false
        if (this.userMenus.includes('PESANAN_MOD')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize()
      // this.getInformasiAset(1)
      // this.getSubAkunPerusahaan(1)
      // this.getMutasi(1)
    },

    methods: {
      initialize () {
        const vm = this

        const requestBody = {
          order_hash: this.$route.params.id,
        }

        axios.post('/v2/t/order/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.detail
            if (res.data.data.detail.doc_tax_inv_trv.file_name) this.fakturPajak = res.data.data.detail.doc_tax_inv_trv
            if (res.data.data.detail.doc_trv_pph.file_name) this.buktiBayarPph = res.data.data.detail.doc_trv_pph
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getInformasiAset (page) {
        const vm = this

        const requestBody = {
          page: page,
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail/asset', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.asetAddOn = res.data.data.addon
            vm.informasiAset = res.data.data.list
            // pagination:
            vm.pageCountInformasiAset = res.data.data.pagination.page_total
            vm.itemCountInformasiAset = res.data.data.pagination.item_count
            vm.itemTotalInformasiAset = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getSubAkunPerusahaan (page) {
        const vm = this

        const requestBody = {
          page: page,
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail/subaccount', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.subAkunPerusahaan = res.data.data.list
            // pagination:
            vm.pageSubAkunPerusahaan = res.data.data.pagination.page_total
            vm.itemCountSubAkunPerusahaan = res.data.data.pagination.item_count
            vm.itemTotalSubAkunPerusahaan = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getMutasi (page) {
        const vm = this

        const requestBody = {
          page: page,
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail/wallet', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.mutasiDompet = res.data.data.list
            // pagination:
            vm.pageCountMutasiDompet = res.data.data.pagination.page_total
            vm.itemCountMutasiDompet = res.data.data.pagination.item_count
            vm.itemTotalMutasiDompet = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      blokirPenyewa (status) {
        const requestBody = {
          cust_hash: this.$route.params.id,
          pwd: this.password,
          new_status: status,
        }

        axios.post('/v1/t/customer/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dialogBlokirPenyewa = false
            this.dialogUnBlokirPenyewa = false
            this.password = ''
            this.$toast.success('Berhasil mengubah status penyewa')
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      uploadFakturPajakChange (e) {
        // console.log(e)
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', e)
        requestBody.append('dest', 'orderDoc')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.fakturPajak = res.data.data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      uploadBuktiBayarPphChange (e) {
        // console.log(e)
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', e)
        requestBody.append('dest', 'orderDoc')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.buktiBayarPph = res.data.data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      uploadFakturPajak () {
        const requestBody = {
          order_hash: this.$route.params.id,
          file_hash: this.fakturPajak.tmp_file_hash,
        }

        axios.post('/v2/t/order/submitTax', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil upload faktur pajak')
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      uploadBayarPph () {
        const requestBody = {
          order_hash: this.$route.params.id,
          file_hash: this.buktiBayarPph.tmp_file_hash,
        }

        axios.post('/v2/t/order/submitPph', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil upload bukti bayar Pph')
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      uploadFakturPajakClick () {
        document.getElementById('upload-faktur-pajak').click()
      },

      uploadBuktiBayarPphClick () {
        document.getElementById('upload-bayar-pph').click()
      },

      onChangePageMutasiDompet (e) {
        this.initialize(e)
      },

      onChangePageInformasiAset (e) {
        this.initialize(e)
      },

      onChangePageSubAkunPerusahaan (e) {
        this.initialize(e)
      },

      downloadFakturPajak () {
        window.open(this.fakturPajak.link, '_blank')
      },

      downloadBuktiBayarPph () {
        window.open(this.buktiBayarPph.link, '_blank')
      },

      navBack () {
        this.$router.push({ name: 'Pesanan' })
      },

      downloadAttachment (data) {
        const type = data.file_type.toUpperCase()
        if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
          const modal = document.getElementById('myModal')
          const modalImg = document.getElementById('img01')
          modal.style.display = 'block'
          if (data.url) modalImg.src = data.url
          if (data.path) modalImg.src = data.path
          if (data.file_path_view_only) modalImg.src = data.file_path_view_only
          const span = document.getElementsByClassName('close')[0]
          span.onclick = function () {
            modal.style.display = 'none'
          }
        } else if (type === 'PDF') {
          window.open(data.url, '_blank')
        } else {
          window.open(data.url_download, '_blank')
        }
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 10;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
